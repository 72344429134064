import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton, Button, Typography, Divider, Box } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddIcon from '@mui/icons-material/Add';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useEffect, useState } from 'react';

import { bgBlur } from '../../../utils/cssStyles';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
import { HEADER, NAV } from '../../../config-global';
import Logo from '../../../components/logo';
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import { PATH_USER_DASHBOARD, PATH_SELLER_DASHBOARD } from '../../../routes/paths';
import { useAuthContext } from '../../../auth/useAuthContext';
import { ROLE } from '../../../utils/constants';
import { fCurrency } from "../../../utils/formatNumber";

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const { onToggleMode, themeMode } = useSettingsContext();

  const [time, setTime] = useState(moment().format("LLL"));

  const { user } = useAuthContext();

  const theme = useTheme();

  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(() => moment().format("LLL"));
    }, 1000)
    return () => clearInterval(interval);
  })

  const isAdmin = user.role_id === ROLE.admin.id

  const isSeller = user.role_id === ROLE.seller.id

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      <Searchbar />

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {!isAdmin && isDesktop && (
          <>
            <Button
              variant="contained"
              sx={{
                padding: '8px 12px',
                gap: '5px',
              }}
              component={RouterLink}
              to={PATH_USER_DASHBOARD.transaction.root}
            >
              <AccountBalanceWalletIcon />
              <Typography variant="body2">Balance : {fCurrency(user.balance)}</Typography>
            </Button>

            <Divider orientation="vertical" variant="middle" flexItem />

            <Button
              variant="contained"
              sx={{
                padding: '8px 12px',
                gap: '5px',
              }}
              component={RouterLink}
              to={isSeller ? PATH_SELLER_DASHBOARD.order.create : PATH_USER_DASHBOARD.order.create}
            >
              <AddIcon />
              <Typography variant="body2">Create New Label</Typography>
            </Button>

            <Divider orientation="vertical" variant="middle" flexItem />

            <Box color="text.primary">
              {time}
            </Box>

            <Divider orientation="vertical" variant="middle" flexItem />
          </>
        )}
        <IconButton aria-label="mode" onClick={() => onToggleMode()}>
          {themeMode === 'light' ? <WbSunnyIcon /> : <DarkModeIcon />}
        </IconButton>

        <Divider orientation="vertical" variant="middle" flexItem />

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
