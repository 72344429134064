import { Navigate, useRoutes } from 'react-router-dom';

import {
  AdminDashboard,
  AdminOrderView,
  AdminOrderList,
  AdminOrderSetting,
  AdminUserList,
  AdminUserView,
  AdminTicketList,
  AdminTicketView,
  AdminDepositList,
  AdminWithdraw,
  AdminTransactionAll,
  AdminSystemSettings,
  AdminTrackingBulk,
  AdminTrackingList,

  UserTicketList,
  UserTicketView,
  UserDeposit,
  UserOrderCreate,
  UserOrderList,
  UserAccount,
  UserAddressBookList,
  UserOrderDetail,
  UserOrderPricing,
  UserDashboard,
  UserTransactionAll,
  UserReferral,
  UserOrderBulk,
  UserOrderEdit,

  HomePage,
  LoginPage,
  RegisterPage,
  NewPasswordPage,
  ResetPasswordPage,

  SellerTicketList,
  SellerTicketView,
  SellerDeposit,
  SellerOrderCreate,
  SellerOrderList,
  SellerAccount,
  SellerAddressBookList,
  SellerOrderDetail,
  SellerOrderPricing,
  SellerDashboard,
  SellerReferralOrder,
  SellerReferralAnalytics,
  SellerWithdraw,
  SellerTransactionAll,
  SellerReferralDeposits,
  SellerReferralUserList,
  SellerReferralUserView,

  Error404,
  Error500,
  APIDocs,
  AdminTrackingRoutingCode,
  AdminOrderEdit,
} from './elements';
import GuestGuard from "../auth/GuestGuard";
import AuthGuard from "../auth/AuthGuard";
import MainLayout from "../layouts/main";
import DashboardLayout from '../layouts/dashboard';
import RoleBasedGuard from '../auth/RoleBasedGuard';
import { ROLE } from '../utils/constants';
import CompactLayout from "../layouts/compact";

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <ResetPasswordPage />
            </GuestGuard>
          ),
        },
        {
          path: 'new-password',
          element: (
            <GuestGuard>
              <NewPasswordPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
      ],
    },
    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
      ],
    },
    {
      path: 'user',
      element: (
        <AuthGuard path="user">
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard" replace />, index: true },
        { path: 'dashboard', element: <UserDashboard /> },
        { path: 'pricing', element: <UserOrderPricing /> },
        {
          path: 'tickets',
          children: [
            { element: <Navigate to="/user/tickets/list" replace />, index: true },
            { path: 'list', element: <UserTicketList /> },
            { path: ':id/view', element: <UserTicketView /> },
          ],
        },
        {
          path: 'account',
          children: [
            { element: <Navigate to="/user/account/profile" replace />, index: true },
            { path: 'profile', element: <UserAccount /> },
            { path: 'referral', element: <UserReferral /> },
          ],
        },
        {
          path: 'transactions',
          children: [
            { element: <Navigate to="/user/transactions/deposits" replace />, index: true },
            { path: 'all', element: <UserTransactionAll /> },
            { path: 'deposits', element: <UserDeposit /> },
          ],
        },
        {
          path: 'orders',
          children: [
            { element: <Navigate to="/user/orders/list" replace />, index: true },
            { path: 'list', element: <UserOrderList /> },
            { path: 'create', element: <UserOrderCreate /> },
            { path: ':id/edit', element: <UserOrderEdit /> },
            { path: 'bulk', element: <UserOrderBulk /> },
            { path: ':id/view', element: <UserOrderDetail /> },
          ],
        },
        {
          path: 'address-books',
          children: [
            { element: <Navigate to="/user/address-books/list" replace />, index: true },
            { path: 'list', element: <UserAddressBookList /> },
          ],
        },
        { path: 'api-docs', element: <APIDocs /> },
      ]
    },
    {
      path: 'seller',
      element: (
        <AuthGuard path="seller">
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard" replace />, index: true },
        { path: 'dashboard', element: <SellerDashboard /> },
        { path: 'pricing', element: <SellerOrderPricing /> },
        {
          path: 'tickets',
          children: [
            { element: <Navigate to="/seller/tickets/list" replace />, index: true },
            { path: 'list', element: <SellerTicketList /> },
            { path: ':id/view', element: <SellerTicketView /> },
          ],
        },
        {
          path: 'account',
          children: [
            { element: <Navigate to="/seller/account/profile" replace />, index: true },
            { path: 'profile', element: <SellerAccount /> },
          ],
        },
        {
          path: 'transactions',
          children: [
            { element: <Navigate to="/seller/transactions/deposits" replace />, index: true },
            { path: 'all', element: <SellerTransactionAll /> },
            { path: 'deposits', element: <SellerDeposit /> },
            { path: 'withdraw', element: <SellerWithdraw /> },
          ],
        },
        {
          path: 'referral',
          children: [
            { element: <Navigate to="/seller/referral/orders" replace />, index: true },
            { path: 'analytics', element: <SellerReferralAnalytics /> },
            { path: 'orders', element: <SellerReferralOrder /> },
            { path: 'users', element: <SellerReferralUserList /> },
            { path: 'users/:id/view', element: <SellerReferralUserView /> },
            { path: 'deposits', element: <SellerReferralDeposits /> },
          ],
        },
        {
          path: 'orders',
          children: [
            { element: <Navigate to="/seller/orders/list" replace />, index: true },
            { path: 'list', element: <SellerOrderList /> },
            { path: 'create', element: <SellerOrderCreate /> },
            { path: ':id/view', element: <SellerOrderDetail /> },
          ],
        },
        {
          path: 'address-books',
          children: [
            { element: <Navigate to="/seller/address-books/list" replace />, index: true },
            { path: 'list', element: <SellerAddressBookList /> },
          ],
        },
      ]
    },
    {
      path: 'admin',
      element: (
        <RoleBasedGuard roles={[ROLE.admin.name]} hasContent>
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [
        { element: <Navigate to="/" replace />, index: true },
        { path: 'dashboard', element: <AdminDashboard /> },
        {
          path: 'system-settings',
          children: [
            { element: <Navigate to="/admin/system-settings/list" replace />, index: true },
            { path: 'list', element: <AdminSystemSettings /> },
          ],
        },
        {
          path: 'orders',
          children: [
            { element: <Navigate to="/admin/orders/list" replace />, index: true },
            { path: 'list', element: <AdminOrderList /> },
            { path: ':id/view', element: <AdminOrderView /> },
            { path: ':id/edit', element: <AdminOrderEdit /> },
            { path: 'setting', element: <AdminOrderSetting /> },
          ],
        },
        {
          path: 'users',
          children: [
            { element: <Navigate to="/admin/users/list" replace />, index: true },
            { path: 'list', element: <AdminUserList /> },
            { path: ':id/view', element: <AdminUserView /> },
          ],
        },
        {
          path: 'transactions',
          children: [
            { element: <Navigate to="/admin/transactions/deposits" replace />, index: true },
            { path: 'all', element: <AdminTransactionAll /> },
            { path: 'deposits', element: <AdminDepositList /> },
            { path: 'withdraw', element: <AdminWithdraw /> },
          ],
        },
        {
          path: 'tracking',
          children: [
            { element: <Navigate to="/admin/tracking/list" replace />, index: true },
            { path: 'list', element: <AdminTrackingList /> },
            { path: 'routing-code', element: <AdminTrackingRoutingCode /> },
            { path: 'bulk', element: <AdminTrackingBulk /> },
          ],
        },
        {
          path: 'tickets',
          children: [
            { element: <Navigate to="/admin/tickets/list" replace />, index: true },
            { path: 'list', element: <AdminTicketList /> },
            { path: ':id/view', element: <AdminTicketView /> },
          ],
        },
      ]
    },
    {
      element: <CompactLayout />,
      children: [
        { path: '404', element: <Error404 /> },
        { path: '500', element: <Error500 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
