import axios from 'axios';
import { first, map } from 'lodash';
import { toast } from 'react-toastify';

import { BASE_URL, HTTP_BAD_REQUEST, HTTP_UNPROCESSABLE_CONTENT, VERSION, HTTP_UNAUTHORIZED } from './constants';
import { PATH_AUTH } from '../routes/paths';

class Service {
  constructor() {
    this.instance = axios.create({
      baseURL: BASE_URL + VERSION,
    });
    this.instance.interceptors.response.use(this.handleSuccess, this.handleError);
    const token = localStorage.getItem('accessToken')
    if (token) {
      this.setBearerToken(token)
    }
  }

  setHeaderDefault = headers => {
    map(headers, (value, key) => {
      this.instance.defaults.headers.common[key] = value;
    });
  };

  setBearerToken = token => {
    this.setHeaderDefault({ Authorization: `Bearer ${token}` });
  };

  // eslint-disable-next-line class-methods-use-this
  handleSuccess = res => res;

  // eslint-disable-next-line class-methods-use-this
  handleError = err => {
    try {
      const {
        response: { status, data },
      } = err;

      const ignoreNotifyError = [
        HTTP_UNPROCESSABLE_CONTENT,
        HTTP_UNAUTHORIZED
      ]

      if (status >= HTTP_BAD_REQUEST && !ignoreNotifyError.includes(status)) {
        toast.error(first(data.errors).message);
      }

      if (status === HTTP_UNAUTHORIZED) {
        // eslint-disable-next-line no-return-assign
        return window.location.pathname === PATH_AUTH.login ? { data } : window.location.href = PATH_AUTH.login;
      }

      return { data, status };
    } catch (error) {
      return error.response;
    }
  };

  get = (url, params = {}) => this.instance.get(url, { params });

  post = (url, body, config) => this.instance.post(url, body, config);

  patch = (url, body) => this.instance.patch(url, body);

  put = (url, body) => this.instance.put(url, body);

  delete = (url, id) => this.instance.delete(url, id);
}

export default new Service();
