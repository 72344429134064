import PropTypes from 'prop-types';

import Provider from './Sentry';

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default function ErrorBoundary({ children }) {
  return (<Provider>{children}</Provider>)
}
