import * as Sentry from "@sentry/react";
import PropTypes from 'prop-types';
import { useEffect } from "react";

import FallbackComponent from '../../ErrorPage/500';


SentryProvider.propTypes = {
  children: PropTypes.node,
};

export default function SentryProvider({ children }) {
  const { REACT_APP_DSN_SENTRY } = process.env;

  useEffect(() => {
    if (REACT_APP_DSN_SENTRY) {
      Sentry.init({
        dsn: process.env.REACT_APP_DSN_SENTRY,
        integrations: [
          new Sentry.BrowserTracing({
            tracePropagationTargets: process.env.REACT_APP_PROPAGATION_TARGET,
          }),
          new Sentry.Replay(),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  }, [])

  const myFallback = <FallbackComponent />;

  if (!REACT_APP_DSN_SENTRY) {
    return children;
  }

  return (
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  )
}
