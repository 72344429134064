import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import TelegramIcon from '@mui/icons-material/Telegram';

import { LINK_TELEGRAM, PATH_SELLER_DASHBOARD } from '../../../routes/paths';
import SvgColor from "../../../components/svg-color";

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  dashboard: icon('ic_dashboard'),
  user: icon('ic_user'),
  order: icon('ic_ecommerce'),
  ticket: <SupportAgentIcon />,
  addressBook: <EditLocationIcon />,
  pricing: <PriceChangeIcon />,
  deposit: icon('ic_invoice'),
  userActivities: <EditNoteIcon />,
  referral: <Diversity3Icon />,
  telegram: <TelegramIcon />,
};

const sellerNavigation = [
  {
    subheader: 'general',
    items: [
      { title: 'dashboard', path: PATH_SELLER_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
      { title: 'pricing', path: PATH_SELLER_DASHBOARD.pricing.root, icon: ICONS.pricing },
    ],
  },
  {
    subheader: 'management',
    items: [
      {
        title: 'transaction',
        path: PATH_SELLER_DASHBOARD.transaction.root,
        icon: ICONS.deposit,
        children: [
          { title: 'all', path: PATH_SELLER_DASHBOARD.transaction.all },
          { title: 'deposit', path: PATH_SELLER_DASHBOARD.transaction.deposit },
          { title: 'withdraw', path: PATH_SELLER_DASHBOARD.transaction.withdraw },
        ],
      },
      {
        title: 'order',
        path: PATH_SELLER_DASHBOARD.order.root,
        icon: ICONS.order,
        children: [
          { title: 'list', path: PATH_SELLER_DASHBOARD.order.list },
          { title: 'create', path: PATH_SELLER_DASHBOARD.order.create },
        ],
      },
      {
        title: 'referral',
        path: PATH_SELLER_DASHBOARD.referral.root,
        icon: ICONS.referral,
        children: [
          { title: 'analytics', path: PATH_SELLER_DASHBOARD.referral.analytics },
          { title: 'orders', path: PATH_SELLER_DASHBOARD.referral.orders },
          { title: 'users', path: PATH_SELLER_DASHBOARD.referral.users },
          { title: 'deposits', path: PATH_SELLER_DASHBOARD.referral.deposits },
        ],
      },
      {
        title: 'my account',
        path: PATH_SELLER_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'profile', path: PATH_SELLER_DASHBOARD.user.profile },
        ],
      },
      {
        title: 'address book',
        path: PATH_SELLER_DASHBOARD.addressBook.list,
        icon: ICONS.addressBook,
      },
      {
        title: 'ticket',
        path: PATH_SELLER_DASHBOARD.ticket.list,
        icon: ICONS.ticket,
      },
    ],
  },
  {
    subheader: 'contact',
    items: [
      { title: 'telegram', path: LINK_TELEGRAM, icon: ICONS.telegram },
    ],
  },
]

export default sellerNavigation
