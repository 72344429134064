import { useEffect, useRef } from "react";
import { BASE_URL_GOOGLE_MAPS, GOOGLE_MAPS_API_KEY } from "../../constants";


function loadScriptGeocode(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

function loadedGeocode(googleAPILoaded) {
  if (typeof window !== 'undefined' && !googleAPILoaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScriptGeocode(
        `${BASE_URL_GOOGLE_MAPS}/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&region=US&components=country:US`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    googleAPILoaded.current = true;
  }
}

export default function GoogleMapAPI() {
  const googleAPILoaded = useRef(false);

  useEffect(() => {
    loadedGeocode(googleAPILoaded)
  }, [])

  return null;
}
