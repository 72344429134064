import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import EditNoteIcon from '@mui/icons-material/EditNote';
import TelegramIcon from '@mui/icons-material/Telegram';
import LinkIcon from '@mui/icons-material/Link';

import { PATH_USER_DASHBOARD, LINK_TELEGRAM } from '../../../routes/paths';
import SvgColor from '../../../components/svg-color';
import { ROLE } from '../../../utils/constants';
import { AuthContext } from "../../../auth/JwtContext";
import { useAuthContext } from "../../../auth/useAuthContext";
import adminNavigation from "./admin-navigation";
import sellerNavigation from "./seller-navigation";

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  dashboard: icon('ic_dashboard'),
  user: icon('ic_user'),
  order: icon('ic_ecommerce'),
  ticket: <SupportAgentIcon />,
  addressBook: <EditLocationIcon />,
  pricing: <PriceChangeIcon />,
  deposit: icon('ic_invoice'),
  userActivities: <EditNoteIcon />,
  telegram: <TelegramIcon />,
  link: <LinkIcon />,
};

const NavConfig = () => {
  const { user } = useAuthContext(AuthContext);

  if (user.role_id === ROLE.admin.id) {
    return adminNavigation
  }

  if (user.role_id === ROLE.seller.id) {
    return sellerNavigation
  }

  return [
    {
      subheader: 'general',
      items: [
        { title: 'dashboard', path: PATH_USER_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
        { title: 'pricing', path: PATH_USER_DASHBOARD.pricing.root, icon: ICONS.pricing },
      ],
    },
    {
      subheader: 'management',
      items: [
        {
          title: 'transaction',
          path: PATH_USER_DASHBOARD.transaction.root,
          icon: ICONS.deposit,
          children: [
            { title: 'all', path: PATH_USER_DASHBOARD.transaction.all },
            { title: 'deposit', path: PATH_USER_DASHBOARD.transaction.deposit },
          ],
        },
        {
          title: 'order',
          path: PATH_USER_DASHBOARD.order.root,
          icon: ICONS.order,
          children: [
            { title: 'list', path: PATH_USER_DASHBOARD.order.list },
            { title: 'import CSV', path: PATH_USER_DASHBOARD.order.bulk },
            { title: 'create', path: PATH_USER_DASHBOARD.order.create },
          ],
        },
        {
          title: 'my account',
          path: PATH_USER_DASHBOARD.user.root,
          icon: ICONS.user,
          children: [
            { title: 'profile', path: PATH_USER_DASHBOARD.user.profile },
            { title: 'referral', path: PATH_USER_DASHBOARD.user.referral },
          ],
        },
        {
          title: 'address book',
          path: PATH_USER_DASHBOARD.addressBook.list,
          icon: ICONS.addressBook,
        },
        {
          title: 'ticket',
          path: PATH_USER_DASHBOARD.ticket.list,
          icon: ICONS.ticket,
        },
      ],
    },
    {
      subheader: 'Developer',
      items: [
        { title: 'API Document', path: PATH_USER_DASHBOARD.api.root, icon: ICONS.link },
      ],
    },
    {
      subheader: 'contact',
      items: [
        { title: 'telegram', path: LINK_TELEGRAM, icon: ICONS.telegram },
      ],
    },
  ]
};

export default NavConfig;
