import { Button, Typography, Box, Container } from '@mui/material';

import SeverErrorIllustration from './components/SeverErrorIllustration';
import { useAuthContext } from '../../../auth/useAuthContext';
import { PATH_AFTER_LOGIN } from '../../../routes/paths';
import './styles.css';

export default function Page500() {
  const { user } = useAuthContext();

  return (
    <Container>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        maxWidth: '400px',
        minHeight: '100vh',
        textAlign: 'center',
        justifyContent: 'center',
      }}>
        <Box>
          <Box>
            <Typography
              variant="h3"
              paragraph
              sx={{
                margin: '0px 0px 16px',
                fontWeight: 700,
                lineHeight: 1.5,
                fontSize: {
                  xs: '1.625rem',
                  md: '1.875rem',
                  lg: '2rem',
                },
                fontFamily: '"Public Sans", sans-serif',
              }}
            >
              500 Internal Server Error
            </Typography>
          </Box>

          <Box>
            <Typography sx={{ color: 'text.secondary' }}>
              There was an error, please try again later.
            </Typography>
          </Box>

          <Box>
            <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
          </Box>

          <Button
            component="a"
            href={PATH_AFTER_LOGIN[user.role_id]}
            size="large"
            variant="contained"
            sx={{
              backgroundColor: '#00ab55',
              boxShadow: 'none',
              height: '48px',
              fontSize: '15px',
              fontFamily: '"Public Sans", sans-serif',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#007b55',
              }
            }}
          >
            Go to Home
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
