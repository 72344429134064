import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';

import Service from '../service';
import localStorageAvailable from '../utils/localStorageAvailable';
import { setSession } from './utils';

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'AUTH_FAILED') {
    return {
      ...state,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'GET_ME') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

export const AuthContext = createContext(null);

AuthProvider.propTypes = {
  children: PropTypes.node,
};

const actionAuthFailed = () => ({
  type: 'INITIAL',
  payload: {
    isAuthenticated: false,
    user: null,
  },
})

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      if (accessToken) {
        setSession(accessToken);

        const response = await Service.get('/auth/me');
        const { data: user, status } = response.data;

        if (status) {
          dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          setSession();
          dispatch(actionAuthFailed());
        }
      } else {
        dispatch(actionAuthFailed());
      }
    } catch (error) {
      dispatch(actionAuthFailed());
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback((user) => {
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  }, [])

  // REGISTER
  const register = useCallback((user) => {
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ME
  const getMe = useCallback(async () => {
    const response = await Service.get('/auth/me');

    if (response) {
      const { data: user, status } = response.data || {};
      if (status) {
        dispatch({
          type: 'GET_ME',
          payload: {
            user
          }
        });
      }
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      register,
      logout,
      getMe,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, login, logout, register, getMe]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
