import { m } from 'framer-motion';
import { Button, Container, Typography } from '@mui/material';

import { MotionContainer, varBounce } from '../animate';

export default function PermissionDenied() {
  return (
    <Container component={MotionContainer} sx={{ textAlign: 'center', paddingTop: 3 }}>
      <m.div variants={varBounce().in}>
        <Typography variant="h3" paragraph>
          Permission Denied
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary' }}>
          You do not have permission to access this page
        </Typography>
      </m.div>

      <Button onClick={() => {window.location.href = '/'}}>Go back</Button>
    </Container>
  )
};
