export const PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME
export const PAGINATION = {
  PER_PAGE: process.env.REACT_APP_PAGINATION_PER_PAGE || 10
}
export const ORDER = {
  STATUS: {
    PROCESSING: 1,
    DONE: 2,
    CANCELED: 3,
  }
}

export const UNIT = {
  LBS: 'lbs',
  INCH: 'inch',
}

export const MODE = {
  DEFAULT: 0,
  OVERRIDE: 1,
}

export const ROLE = {
  admin: {
    id: 2,
    name: 'admin'
  },
  user: {
    id: 1,
    name: 'user'
  },
  seller: {
    id: 3,
    name: 'seller'
  }
}
export const DEFAULT_TAB = 'Default'
