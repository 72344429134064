export const STATUS = {
  OPEN: { id: 1, name: 'OPEN' },
  CLOSE: { id: 2, name: 'CLOSE' },
}

export const CATEGORY = {
  ORDER: { id: 1, name: 'ORDER' },
  DEPOSIT: { id: 2, name: 'DEPOSIT' },
  WITHDRAW: { id: 3, name: 'WITHDRAW' },
  OTHER: { id: 4, name: 'OTHER' },
}

export const CATEGORIES_USER = [
  { label: 'Order', value: 1 },
  { label: 'Deposit', value: 2 },
  { label: 'Withdraw', value: 3 },
  { label: 'Other', value: 4 }
]

export const STATUS_OPTIONS = [
  { id: STATUS.OPEN.id, title: STATUS.OPEN.name },
  { id: STATUS.CLOSE.id, title: STATUS.CLOSE.name },
];

export const CATEGORY_OPTIONS = [
  { id: CATEGORY.ORDER.id, title: CATEGORY.ORDER.name },
  { id: CATEGORY.DEPOSIT.id, title: CATEGORY.DEPOSIT.name },
  { id: CATEGORY.WITHDRAW.id, title: CATEGORY.WITHDRAW.name },
  { id: CATEGORY.OTHER.id, title: CATEGORY.OTHER.name },
];
