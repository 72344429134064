import { Suspense, lazy as lazyLoad } from 'react';

import LoadingScreen from '../components/loading-screen';

const lazy = (componentImport) => lazyLoad(() => new Promise((resolve, reject) => {

  const hasRefreshed = JSON.parse(
    window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
  );

  componentImport().then((component) => {
    window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
    resolve(component);
  }).catch((error) => {
    if (!hasRefreshed && error.name === 'ChunkLoadError') {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
      return window.location.reload();
    }
    reject(error);
  });
}));

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export const HomePage = Loadable(lazy(() => import('../container/HomePage')));
export const LoginPage = Loadable(lazy(() => import('../container/Auth/Login')));
export const RegisterPage = Loadable(lazy(() => import('../container/Auth/Register')));
export const ResetPasswordPage = Loadable(lazy(() => import('../container/Auth/ResetPassword')));
export const NewPasswordPage = Loadable(lazy(() => import('../container/Auth/NewPassword')));

export const Error404 = Loadable(lazy(() => import('../container/ErrorPage/404')));
export const Error500 = Loadable(lazy(() => import('../container/ErrorPage/500')));

export const APIDocs = Loadable(lazy(() => import('../container/Users/APIDocument')));

export const UserDashboard = Loadable(lazy(() => import('../container/Users/Dashboard')));
export const UserTicketList = Loadable(lazy(() => import('../container/Users/Ticket/List')));
export const UserTicketView = Loadable(lazy(() => import('../container/Users/Ticket/View')));
export const UserDeposit = Loadable(lazy(() => import('../container/Users/Deposit')));
export const UserOrderCreate = Loadable(lazy(() => import('../container/Users/Order/Create')));
export const UserOrderEdit = Loadable(lazy(() => import('../container/Users/Order/Edit')));
export const UserOrderList = Loadable(lazy(() => import('../container/Users/Order/List')));
export const UserOrderDetail = Loadable(lazy(() => import('../container/Users/Order/View')));
export const UserAccount = Loadable(lazy(() => import('../container/Users/MyAccount/Profile')));
export const UserReferral = Loadable(lazy(() => import('../container/Users/MyAccount/Referral')));
export const UserAddressBookList = Loadable(lazy(() => import('../container/Users/AddressBook/List')));
export const UserOrderPricing = Loadable(lazy(() => import('../container/Users/Order/Pricing')));
export const UserOrderBulk = Loadable(lazy(() => import('../container/Users/Order/Bulk')));
export const UserTransactionAll = Loadable(lazy(() => import('../container/Users/TransactionAll')));

export const SellerDashboard = Loadable(lazy(() => import('../container/Seller/Dashboard')));
export const SellerTicketList = Loadable(lazy(() => import('../container/Seller/Ticket/List')));
export const SellerTicketView = Loadable(lazy(() => import('../container/Seller/Ticket/View')));
export const SellerDeposit = Loadable(lazy(() => import('../container/Seller/Deposit')));
export const SellerOrderCreate = Loadable(lazy(() => import('../container/Seller/Order/Create')));
export const SellerOrderList = Loadable(lazy(() => import('../container/Seller/Order/List')));
export const SellerOrderDetail = Loadable(lazy(() => import('../container/Seller/Order/View')));
export const SellerAccount = Loadable(lazy(() => import('../container/Seller/MyAccount/Profile')));
export const SellerAddressBookList = Loadable(lazy(() => import('../container/Seller/AddressBook/List')));
export const SellerOrderPricing = Loadable(lazy(() => import('../container/Seller/Order/Pricing')));
export const SellerReferralOrder = Loadable(lazy(() => import('../container/Seller/Referral/Orders')));
export const SellerReferralAnalytics = Loadable(lazy(() => import('../container/Seller/Referral/Analytics')));
export const SellerReferralDeposits = Loadable(lazy(() => import('../container/Seller/Referral/Deposits')));
export const SellerReferralUserList = Loadable(lazy(() => import('../container/Seller/Referral/User/List')));
export const SellerReferralUserView = Loadable(lazy(() => import('../container/Seller/Referral/User/View')));
export const SellerWithdraw = Loadable(lazy(() => import('../container/Seller/Withdraw')));
export const SellerTransactionAll = Loadable(lazy(() => import('../container/Seller/TransactionAll')));

export const AdminDashboard = Loadable(lazy(() => import('../container/Admin/Dashboard')));
export const AdminSystemSettings = Loadable(lazy(() => import('../container/Admin/SystemSettings')));
export const AdminOrderList = Loadable(lazy(() => import('../container/Admin/Order/List')));
export const AdminOrderView = Loadable(lazy(() => import('../container/Admin/Order/View')));
export const AdminOrderEdit = Loadable(lazy(() => import('../container/Admin/Order/Edit')));
export const AdminOrderSetting = Loadable(lazy(() => import('../container/Admin/Order/Setting')));
export const AdminUserList = Loadable(lazy(() => import('../container/Admin/User/List')));
export const AdminUserView = Loadable(lazy(() => import('../container/Admin/User/View')));
export const AdminTicketList = Loadable(lazy(() => import('../container/Admin/Ticket/List')));
export const AdminTicketView = Loadable(lazy(() => import('../container/Admin/Ticket/View')));
export const AdminDepositList = Loadable(lazy(() => import('../container/Admin/Deposit/List')));
export const AdminWithdraw = Loadable(lazy(() => import('../container/Admin/Withdraw')));
export const AdminTransactionAll = Loadable(lazy(() => import('../container/Admin/TransactionAll')));
export const AdminTrackingBulk = Loadable(lazy(() => import('../container/Admin/Tracking/Bulk')));
export const AdminTrackingRoutingCode = Loadable(lazy(() => import('../container/Admin/Tracking/RoutingCode')));
export const AdminTrackingList = Loadable(lazy(() => import('../container/Admin/Tracking/List')));
