// ----------------------------------------------------------------------
import { ROLE } from '../utils/constants';
import { HOST_API_KEY } from "../config-global";

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_ADMIN = '/admin';
const ROOTS_USER = '/user';
const ROOTS_SELLER = '/seller';

export const PATH_AFTER_LOGIN = {
  [ROLE.user.id] : '/user/dashboard',
  [ROLE.admin.id] : '/admin/dashboard',
  [ROLE.seller.id] : '/seller/dashboard',
}

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const LINK_TELEGRAM = 'https://t.me/shippointio';
export const LINK_TELEGRAM_SP = 'https://t.me/shippointsupport';
export const DOCUMENT_API_URL = `${HOST_API_KEY}/docs/api.json`

export const PATH_ADMIN_DASHBOARD = {
  root: ROOTS_ADMIN,
  general: {
    dashboard: path(ROOTS_ADMIN, '/dashboard'),
    settingsSystem: path(ROOTS_ADMIN, '/system-settings'),
  },
  order: {
    root: path(ROOTS_ADMIN, '/orders'),
    list: path(ROOTS_ADMIN, '/orders/list'),
    setting: path(ROOTS_ADMIN, '/orders/setting'),
    view: (id) => path(ROOTS_ADMIN, `/orders/${id}/view`),
    edit: (id) => path(ROOTS_ADMIN, `/orders/${id}/edit`),
  },
  userActive: {
    root: path(ROOTS_ADMIN, '/users-activities'),
    list: path(ROOTS_ADMIN, '/users-activities/list'),
  },
  user: {
    root: path(ROOTS_ADMIN, '/users'),
    list: path(ROOTS_ADMIN, '/users/list'),
    view: (id) => path(ROOTS_ADMIN, `/users/${id}/view`),
  },
  ticket: {
    root: path(ROOTS_ADMIN, '/tickets'),
    list: path(ROOTS_ADMIN, '/tickets/list'),
    view: (id) => path(ROOTS_ADMIN, `/tickets/${id}/view`),
  },
  transaction: {
    root: path(ROOTS_ADMIN, '/transactions'),
    all: path(ROOTS_ADMIN, '/transactions/all'),
    deposit: path(ROOTS_ADMIN, '/transactions/deposits'),
    withdraw: path(ROOTS_ADMIN, '/transactions/withdraw'),
  },
  tracking: {
    root: path(ROOTS_ADMIN, '/tracking'),
    list: path(ROOTS_ADMIN, '/tracking/list'),
    bulk: path(ROOTS_ADMIN, '/tracking/bulk'),
    routingCode: path(ROOTS_ADMIN, '/tracking/routing-code'),
  },
};

export const PATH_USER_DASHBOARD = {
  root: ROOTS_USER,
  general: {
    dashboard: path(ROOTS_USER, '/dashboard'),
  },
  ticket: {
    root: path(ROOTS_USER, '/tickets'),
    list: path(ROOTS_USER, '/tickets/list'),
    view: (id) => path(ROOTS_USER, `/tickets/${id}/view`),
  },
  transaction: {
    root: path(ROOTS_USER, '/transactions'),
    all: path(ROOTS_USER, '/transactions/all'),
    deposit: path(ROOTS_USER, '/transactions/deposits'),
  },
  pricing: {
    root: path(ROOTS_USER, '/pricing'),
    list: path(ROOTS_USER, '/pricing/list'),
  },
  order: {
    root: path(ROOTS_USER, '/orders'),
    list: path(ROOTS_USER, '/orders/list'),
    bulk: path(ROOTS_USER, '/orders/bulk'),
    edit: (id) => path(ROOTS_USER, `/orders/${id}/edit`),
    create: path(ROOTS_USER, '/orders/create'),
    view: (id) => path(ROOTS_USER, `/orders/${id}/view`),
  },
  user: {
    root: path(ROOTS_USER, '/account'),
    profile: path(ROOTS_USER, '/account/profile'),
    referral: path(ROOTS_USER, '/account/referral'),
  },
  addressBook: {
    root: path(ROOTS_USER, '/address-books'),
    list: path(ROOTS_USER, '/address-books/list'),
    view: (id) => path(ROOTS_USER, `/address-books/${id}/view`),
  },
  api: {
    root: path(ROOTS_USER, '/api-docs'),
  },
};

export const PATH_SELLER_DASHBOARD = {
  root: ROOTS_SELLER,
  general: {
    dashboard: path(ROOTS_SELLER, '/dashboard'),
  },
  ticket: {
    root: path(ROOTS_SELLER, '/tickets'),
    list: path(ROOTS_SELLER, '/tickets/list'),
    view: (id) => path(ROOTS_SELLER, `/tickets/${id}/view`),
  },
  transaction: {
    root: path(ROOTS_SELLER, '/transactions'),
    all: path(ROOTS_SELLER, '/transactions/all'),
    deposit: path(ROOTS_SELLER, '/transactions/deposits'),
    withdraw: path(ROOTS_SELLER, '/transactions/withdraw'),
  },
  pricing: {
    root: path(ROOTS_SELLER, '/pricing'),
    list: path(ROOTS_SELLER, '/pricing/list'),
  },
  order: {
    root: path(ROOTS_SELLER, '/orders'),
    list: path(ROOTS_SELLER, '/orders/list'),
    create: path(ROOTS_SELLER, '/orders/create'),
    view: (id) => path(ROOTS_SELLER, `/orders/${id}/view`),
  },
  referral: {
    root: path(ROOTS_SELLER, '/referral'),
    analytics: path(ROOTS_SELLER, '/referral/analytics'),
    orders: path(ROOTS_SELLER, '/referral/orders'),
    users: path(ROOTS_SELLER, '/referral/users'),
    usersView: (id) => path(ROOTS_SELLER, `/referral/users/${id}/view`),
    deposits: path(ROOTS_SELLER, '/referral/deposits'),
  },
  user: {
    root: path(ROOTS_SELLER, '/account'),
    profile: path(ROOTS_SELLER, '/account/profile'),
    referral: path(ROOTS_SELLER, '/account/referral'),
  },
  addressBook: {
    root: path(ROOTS_SELLER, '/address-books'),
    list: path(ROOTS_SELLER, '/address-books/list'),
    view: (id) => path(ROOTS_SELLER, `/address-books/${id}/view`),
  },
};
