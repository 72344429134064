import PropTypes from 'prop-types';
import { findKey } from 'lodash';
import { Navigate } from 'react-router';

import { ROLE } from '../utils/constants';
import { useAuthContext } from './useAuthContext';
import PermissionDenied from '../components/PermissionDenied';
import { PATH_AUTH } from '../routes/paths';

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
};

export default function RoleBasedGuard({ hasContent, roles, children }) {
  const { user } = useAuthContext();
  const currentRole = findKey(ROLE, { 'id': user?.role_id })
  const token = localStorage.getItem('accessToken');

  if (!user && !token) {
    return <Navigate to={PATH_AUTH.login} />
  }

  if (typeof roles !== 'undefined' && !roles.includes(currentRole)) {
    return (hasContent && currentRole) ? (
      <PermissionDenied />
    ) : null;
  }

  return <> {children} </>;
}
